import { RouteObject } from 'react-router-dom';

import { ExecProgramView } from '@/features/exec_program/views/index.ts';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <ExecProgramView />,
    index: true
  }
];

export { routes };
