import {
  AppShell,
  DefaultMantineColor,
  MantineProvider,
  MantineThemeOverride,
  Tuple
} from '@mantine/core';
import { Outlet } from 'react-router-dom';

import { Header } from './common/components/index.ts';

type AppColorNames = 'blueish' | 'white' | 'black' | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<AppColorNames, Tuple<string, 10>>;
  }
}

const AppTheme: MantineThemeOverride = {
  colors: {
    white: new Array(10).fill('#ffffff') as Tuple<string, 10>,
    black: new Array(10).fill('#000000') as Tuple<string, 10>,
    blueish: [
      '#e2ecff',
      '#b2c5ff',
      '#809fff',
      '#4f78fc',
      '#1e52fa',
      '#0538e1',
      '#002cb0',
      '#001f7f',
      '#00134f',
      '#000620'
    ]
  },
  primaryColor: 'blueish',
  fontFamily: '"Merriweather", serif'
};

const AppLayout = () => {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={AppTheme}>
      <AppShell header={<Header />}>
        <Outlet />
      </AppShell>
    </MantineProvider>
  );
};

export { AppLayout };
