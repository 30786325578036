import { Button, Center, Paper, TextInput, Title } from '@mantine/core';

const ExecProgramView = () => {
  return (
    <Center w="100vw" h="100vh">
      <Paper shadow="xs" p="md" maw={350} w={'100%'}>
        <Center w="100%">
          Time: 00:00:00.000
          <Button>Start Session</Button>
          <Button>Stop Session</Button>
          <TextInput label="Session Min Time" />
          <TextInput label="Session Max Time" />
          <Button>Start Round</Button>
          <Button>Stop Round</Button>
          <Title>Round #01</Title>
          Time: 00:00:00.000
          <br />
          OverPass: 00:00:00.000
          <br />
          Completion: 0%
        </Center>
      </Paper>
    </Center>
  );
};

export { ExecProgramView };
